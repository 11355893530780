import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Card from '../card/card';
import EducationStyle from './experience.module.scss';

export default function Experience() {

    const [expanded, setExpanded] = React.useState(undefined);

    const handleChange = (event, newExpanded) => {
        setExpanded(newExpanded === expanded ? false: newExpanded);
    };

    const fillEntry = (data, idx) => {

        const logo = {
            url: data.institution.url,
            srcUrl: data.institution.logo.file.url,
            title: data.institution.name
        }

        const start = (data.hasOwnProperty('startDate') && data.startDate) ? `${data.startDate} - ` : '';
        const end = (data.hasOwnProperty('endDate') && data.endDate) ? data.endDate : 'current';
        const years = `${start}${end}`

        const details = (data.hasOwnProperty('details') && data.details) ? data.details.json : ''
        
        return (
            <li key={idx}>
                <Card
                    logo={logo}
                    caption={years}
                    details={details}
                    index={idx}
                    expandedPanel={expanded}
                    handleChange={handleChange}>
                    <p className={EducationStyle.degreeName}>{data.entryName}</p>
                    <p className={EducationStyle.institution}>
                        {data.institution.name}
                        <br />
                        <span className={EducationStyle.institution__location}>
                            {data.institution.location}
                        </span>
                    </p>
                </Card>


            </li>
        )
    }

    const data = useStaticQuery(graphql`
        query {
            allContentfulResumeEntry(
                filter: { entryType: {eq: "experience"}}
                sort: { fields: [endDate, startDate], order: DESC }
            ) {
                nodes {
                    entryName
                    startDate(formatString: "MMM. YYYY")
                    endDate(formatString: "MMM. YYYY")
                    institution {
                        name
                        location
                        url
                        logo {
                            file {
                                url
                            }
                        }
                    }
                    details {
                        json
                    }
                }
            }
        }
    `);

    const entries = data.allContentfulResumeEntry.nodes.map(fillEntry);

    return (
        <ul className={EducationStyle.educationEntryList}>
            {entries}
        </ul>
    )
}