import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import EducationStyle from './education.module.scss';
import Card from '../card/card';

const Education = () => {

    const [expendedEntry, setExpendedEntry] = useState(null);

    const onClickEntry = (index) => {
        if (index === expendedEntry) {
            setExpendedEntry(null)
        } else {
            setExpendedEntry(index)
        }
    }

    const fillEntry = (data, idx) => {

        const logo = {
            url: data.institution.url,
            srcUrl: data.institution.logo.file.url,
            title: data.institution.name
        }

        const start = (data.hasOwnProperty('startDate') && data.startDate) ? `${data.startDate} - ` : '';
        const end = (data.hasOwnProperty('endDate') && data.endDate) ? data.endDate : 'current';
        const years = `${start}${end}`

        const details = (data.hasOwnProperty('details') && data.details) ? data.details.json : '';

        return (
            <li key={idx} onClick={() => onClickEntry(idx)}>
                <Card
                    logo={logo}
                    caption={years}
                    expended={idx === expendedEntry}>
                    <p className={EducationStyle.degreeName}>{data.entryName}</p>
                    <p className={EducationStyle.institution}>
                        {data.institution.name}
                        <br />
                        <span className={EducationStyle.institution__location}>
                            {data.institution.location}
                        </span>
                    </p>
                    <div className={EducationStyle.details}>
                        {documentToReactComponents(details)}
                    </div>
                </Card>
            </li>
        )
    }


    const data = useStaticQuery(graphql`
        query {
            allContentfulResumeEntry(
                filter: { entryType: {eq: "education"}}
                sort: { fields: [endDate, startDate], order: DESC }
            ) {
                nodes {
                    entryName
                    startDate(formatString: "MMM. YYYY")
                    endDate(formatString: "MMM. YYYY")
                    institution {
                        name
                        location
                        url
                        logo {
                            file {
                                url
                            }
                        }
                    }
                    details {
                        json
                    }
                }
            }
        }
    `);

    const entries = data.allContentfulResumeEntry.nodes.map(fillEntry);

    return (
        <ul className={EducationStyle.educationEntryList}>
            {entries}
        </ul>
    )
}

export default Education;