import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import CardStyles from './card.module.scss';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const ExpansionPanel = withStyles({
    root: {
        backgroundColor: 'transparent',
        '-webkit-filter': 'grayscale(100%)',
        filter: 'grayscale(100%)',
        boxShadow: '0px 0px 15px #eeeeee',
        transition: '500ms',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
        '&:hover': {
            boxShadow: '0px 0px 15px #dddddd',
            '-webkit-filter': 'grayscale(0%)',
            filter: 'grayscale(0%)',
            transition: '500ms'
        }
    },
    expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        marginBottom: -1,
        minHeight: 150,
        '&$expanded': {
            minHeight: 150,
        }
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        fontSize: '.8em'
    },
}))(MuiExpansionPanelDetails);

const Card = (props) => {
    const panel = `panel${props.index}`;

    return (

        <ExpansionPanel
            square
            expanded={props.expandedPanel === panel}
            onChange={(val) => props.handleChange(val, panel)}>

            <ExpansionPanelSummary
                aria-controls={`${panel}d-content`}
                id={`${panel}d-header`}>

                <div className={CardStyles.left}>
                    <a
                        href={props.logo.url}
                        title={props.logo.title}
                        target='_blank'
                        rel='noopener noreferrer'>
                        <img
                            className={CardStyles.logo}
                            src={props.logo.srcUrl}
                            alt={props.logo.title} />
                    </a>
                    <div className={CardStyles.caption}>
                        <p>{props.caption}</p>
                    </div>
                </div>

                <div className={CardStyles.content}>
                    {props.children}
                </div>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <span>{documentToReactComponents(props.details)}</span>
            </ExpansionPanelDetails>
        </ExpansionPanel>


    )
}

export default Card;
