import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import 'react-tabs/style/react-tabs.css';
import Education from '../components/education/education';
import Experience from '../components/experience/experience';
import Head from '../components/head';
import Layout from '../components/layout';

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`panel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            <Box p={1}>{children}</Box>
        </Typography>
    );
}

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `panel-${index}`,
    };
}

const AboutPage = () => {

    const [value, setValue] = useState(0);

    function handleChange(event, newValue) {
        setValue(newValue);
    }

    return (
        <Layout>
            <Head title='About' />
            <h1>About</h1>
            <p>I am a PhD student in computational biology at the University of Dundee. I currently work in functional genomics.</p>
                <Tabs
                    value={value}
                    indicatorColor='secondary'
                    variant='fullWidth'
                    onChange={handleChange}
                >
                    <Tab label="Experience" {...a11yProps(0)} />
                    <Tab label="Education" {...a11yProps(1)} />
                    {/* <Tab label="Skills" {...a11yProps(2)} />
                    <Tab label="Miscellaneous" {...a11yProps(3)} /> */}
                </Tabs>

            <TabPanel value={value} index={0}>
                <Experience />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Education />
            </TabPanel>
            <TabPanel value={value} index={2}>
                Item Three
            </TabPanel>
        </Layout >
    )
}

export default AboutPage